import { formatCurrency } from "src/utils";
import NewModal from "../common/Modal-Redesign";
import { useMediaQuery } from "@mui/material";
import { useFilterContext } from "../contexts/FilterContext/FilterContext";
import { useAccountContext } from "../contexts/ToggleAccountContext";
import toast from "react-hot-toast";
import React, { useState, useEffect } from "react";
import { JackpotService } from "src/services";
import { FaSpinner } from "react-icons/fa";
import { SubmitButton } from "../common";

export default function JackpotModal() {
  const isMobile = useMediaQuery("(max-width: 750px)");
  const { setShowLogin, isLogged } = useAccountContext();
  const { selectJackpot, selectedJackpot, setUserEntries, setOpportunities, userEntries } =
    useFilterContext();
  const [modalLoading, setModalLoading] = useState(false);

  useEffect(() => {
    if (!isLogged) {
      setUserEntries(3);
      return;
    }
    JackpotService.getUserEntries().then((remainingEntries) => setUserEntries(remainingEntries));
  }, [isLogged, setUserEntries]);

  const handleSubmission = async () => {
    if (!isLogged) {
      selectJackpot(null);
      setShowLogin(true);
      return;
    }

    if (!selectedJackpot) {
      toast.error("Sorry, you cannot enter this jackpot. Please refresh the page.");
      selectJackpot(null);
      return;
    }
    setModalLoading(true);
    const response = await JackpotService.enterJackpot(selectedJackpot.id);

    if (!response) {
      toast.error("Sorry, something wrong happened.");
      return;
    }

    const entriesResponse = await JackpotService.getUserEntries();

    if (entriesResponse === undefined) {
      return;
    }
    setUserEntries(entriesResponse);

    // We hide the current jackpot
    setOpportunities((previous) =>
      previous.filter((opportunity) => opportunity.opportunity_id !== selectedJackpot.id),
    );

    selectJackpot(null);
    setModalLoading(false);
  };

  if (!selectedJackpot) {
    return null;
  }

  return (
    <NewModal
      open={!!selectJackpot}
      onClose={() => selectJackpot(null)}
      title="Jackpot"
      closeButton={true}
      className="md:max-w-[540px]"
    >
      <div className="mt-10 flex w-full max-w-[300px] justify-center rounded-2xl bg-[#F5EFDE] py-3">
        <p className="text-lg font-bold">
          {formatCurrency(selectedJackpot.points || 0)} MIKI points
        </p>
      </div>
      <p className="my-4 text-center text-lg">
        <a href={selectedJackpot.website} target="_blank" rel="noreferrer">
          <span className="underline">{selectedJackpot?.name}</span>
        </a>{" "}
        may join us soon.
        <br />
        Don&apos;t miss the chance to be rewarded when it happens.
      </p>
      <h3 className="my-2 mt-3 w-full font-bold">Keep in mind:</h3>
      <div className="w-full py-3">
        <div className="flex w-full py-1 pl-2">
          <img src="icons/dice.svg" alt="jackpot icon 1" width={30} className="mr-3" />
          <p>
            You have <strong> {userEntries} </strong> {userEntries === 1 ? "entry" : "entries"}{" "}
            remaining this week.
          </p>
        </div>
        <div className="flex w-full py-1 ">
          <img src="icons/plustwo.svg" alt="jackpot icon 1" width={40} className="mr-3" />
          <p>
            You earn <strong>2</strong> extra entries per week after you purchase your first
            opportunity.
          </p>
        </div>
      </div>
      <p className="w-full pt-10 text-sm">
        By selecting <strong>Enter</strong>, I agree to MindKind&apos;s{" "}
        <span>
          <a href="support/jackpot" target={typeof window !== "undefined" && window.innerWidth < 768 ? "_self" : "_blank"} className="underline">
            Jackpot Rules.
          </a>
        </span>
      </p>
      <>
        {modalLoading ? (
          <div className="brand-color m-auto mt-4 block w-full rounded py-2 px-2 text-center text-xl font-semibold text-white">
            <FaSpinner className="inline-block w-full animate-spin" />
          </div>
        ) : (
          <SubmitButton onClick={handleSubmission} label={"Enter"} disabled={userEntries <= 0} />
        )}
      </>
    </NewModal>
  );
}
