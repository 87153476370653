import React, { useState, useEffect, useCallback } from "react";
import { AccountService, OpportunitiesService } from "src/services";
import { JackpotService } from "src/services";
import InfiniteScroll from "react-infinite-scroll-component";
import { FeedType } from "../contexts/opportunityContext";
import { Skeleton } from "@mui/material";

import { useAccountContext } from "../contexts/ToggleAccountContext";

import { SearchParams, useFilterContext } from "../contexts/FilterContext/FilterContext";
import { MouseEvent } from "react";
import HomeCard from "./HomeCard";
import JackpotModal from "./JackpotModal";
import { useRouter } from "next/router";

export const FilterMessage = ({ filterType }: { filterType: FeedType }) => {
  const [filterMessage, setFilterMessage] = useState<{
    message: string;
    title: string;
  }>({ message: "", title: "" });

  const router = useRouter();

  useEffect(() => {
    if (filterType === FeedType.AROUND) {
      setFilterMessage({
        message: `<span>Join your <a class="text-primary underline" href="/community"> community</a> to discover the exciting opportunities available where you live.</span>`,
        title: "Discover local treasures",
      });
      return;
    }
    if (filterType === FeedType.JAZZED) {
      setFilterMessage({
        message: "As you search, click the heart icon to be alerted when opportunities arise.",
        title: "Ready to experience?",
      });
      return;
    }
    setFilterMessage({
      message: `Local businesses are now in the process of creating new
      opportunities. Stay tuned and get ready!`,
      title: "",
    });
  }, [filterType]);

  const handleLinkClick = (event: MouseEvent) => {
    event.preventDefault();
    const targetLink = (event.target as any).getAttribute("href");
    targetLink && router.push(targetLink);
    return;
  };

  return (
    <>
      <div
        className={
          "mt-[4rem] flex justify-center text-center font-bold md:mt-[15vh] md:text-lg lg:text-xl"
        }
      >
        {filterMessage.title}
      </div>
      {filterMessage.message && (
        <div
          className={`${
            filterMessage.title ? "mt-[1rem] md:mt-[5vh]" : "mt-[3rem] md:mt-[15vh]"
          } flex justify-center text-center md:text-lg lg:text-xl`}
        >
          <span
            dangerouslySetInnerHTML={{ __html: filterMessage?.message }}
            onClick={(e) => handleLinkClick(e)}
          />{" "}
        </div>
      )}
    </>
  );
};

export const HomeSkeleton = () => {
  return (
    <div className="h-fit w-full pt-4">
      <Skeleton variant="rectangular" height="100%" className="aspect-[1/1] w-full rounded-lg" />
      <Skeleton variant="rectangular" height={28} className="mt-6 rounded-md" />
      <Skeleton variant="rectangular" height={18} className="mt-4 rounded-md" />
      <Skeleton variant="rectangular" height={24} className="mt-4 rounded-md" />
      <Skeleton variant="rectangular" height={20} className="mt-4 rounded-md" />
    </div>
  );
};

export default function HomeOpportunities({ filterType }: { filterType: FeedType }) {
  const { isLogged } = useAccountContext();
  const { searchParams, opportunities, setOpportunities, setUserEntries } = useFilterContext();

  const [loading, setLoading] = useState(true);

  const PAGE_SIZE = 10;

  const [pagination, setPagination] = useState({
    page: 0,
    hasMore: true,
  });

  // Used on Around filter
  const [communityZipCode, setCommunityZipCode] = useState("");

  useEffect(() => {
    if (!isLogged) {
      setUserEntries(3);

      return;
    }
    JackpotService.getUserEntries().then((remainingEntries) => setUserEntries(remainingEntries));
  }, [isLogged, setUserEntries]);

  useEffect(() => {
    if (
      (filterType === FeedType.JAZZED && !isLogged) ||
      (filterType === FeedType.AROUND && !communityZipCode)
    ) {
      setOpportunities([]);
      setPagination({
        page: 0,
        hasMore: false,
      });
      setLoading(false);
      return;
    }
  }, [filterType]);

  // Function to load more opportunities
  const loadMore = useCallback(
    async ({
      page,
      searchParams,
      filterType,
    }: {
      page: number;
      searchParams: SearchParams;
      filterType: FeedType;
    }) => {
      if (
        (filterType === FeedType.JAZZED && !isLogged) ||
        (filterType === FeedType.AROUND && !communityZipCode)
      ) {
        setOpportunities([]);
        setPagination({
          page: 0,
          hasMore: true,
        });
        setLoading(false);
        return;
      }
      // Construct data object with offset and limit for API call
      const data = {
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE,
        ...searchParams,
        latitude: searchParams.location.lat,
        longitude: searchParams.location.lng,
      };

      //Added this new line to consider the filter min amount. Previous code is over writing the filter and effecting the search result for minPrice filter
      if (filterType === FeedType.NEW) data.minprice = Math.max(searchParams.minprice, 1);

      const res = await OpportunitiesService.getOpportunitiesFilter(filterType, data);

      if (!res) {
        return;
      }
      const total = res.total;
      const currentOpportunities = res.opportunities;

      setOpportunities((prevOpportunities) =>
        // IN 0 Page reset
        !page ? currentOpportunities : [...prevOpportunities, ...currentOpportunities],
      );

      setPagination((prevPagination) => ({
        ...prevPagination,
        page: page + 1,
        hasMore: total > 0 && (page + 1) * PAGE_SIZE < total,
      }));
      setLoading(false);
    },
    [isLogged, communityZipCode, setOpportunities],
  );

  // Reset Pagination on filter or login status change or the search params
  useEffect(() => {
    if (!isLogged) {
      return;
    }
    AccountService.getInfo().then((response) => {
      const userInfo = response?.data;
      if (!userInfo) {
        return;
      }
      setCommunityZipCode(userInfo?.community?.community_zipCode || "");
    });
  }, [isLogged]);

  // Reset Pagination on filter or login status change or the search params
  useEffect(() => {
    const loadItems = async () => {
      await loadMore({ filterType, searchParams, page: 0 });
    };
    loadItems();
  }, [loadMore, filterType, searchParams, setOpportunities]);

  if (loading) {
    return (
      <div className="safari-home relative w-full pt-10 sm:pt-6 md:pt-2 ">
        <div className="safari-home 3xl:grid-cols-5 relative mb-2 grid grid-cols-1 gap-y-9 gap-x-6 sm:grid-cols-2 sm:gap-y-10 sm:pt-6 md:mb-10 md:grid-cols-2 md:pt-3 ms:grid-cols-3 ml:grid-cols-4">
          {Array.from({ length: 8 }).map((_, index) => (
            <HomeSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="safari-home relative w-full pt-10 sm:pt-6 md:pt-2 ">
      {!opportunities.length && !loading && <FilterMessage filterType={filterType} />}
      <InfiniteScroll
        dataLength={opportunities.length}
        next={() => loadMore({ filterType, searchParams, page: pagination.page })}
        hasMore={pagination.hasMore}
        loader={<h4></h4>}
      >
        <div className="safari-home 3xl:grid-cols-5 relative mb-2 grid grid-cols-1 gap-y-9 gap-x-6 sm:grid-cols-2 sm:gap-y-10 sm:pt-6 md:mb-10 md:grid-cols-2 md:pt-3 ms:grid-cols-3 ml:grid-cols-4">
          {opportunities.map((opportunity, index) => (
            <div key={index} className="group relative">
              <HomeCard opportunity={opportunity} imagePriority={index < 10} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
      <JackpotModal />
    </div>
  );
}
